// loginpage path /login
import React from 'react'
import Login from '../components/Login'

export default function LoginPage() {
  return (
    <div className='mt-20 fadeInAnimation'>
        <Login />
    </div>
  )
}
